// el-button 点击后恢复原样
export const buttonAutoBlurWhenClicked = () => {
  document.onmouseup = e => {
    let btnEl = null
    const path = e.path || e.composedPath()
    const maxI = path.length > 3 ? 3 : path.length
    for (let i = 0; i < maxI; i++) {
      const el = path[i]
      const classStr = el !== document && el !== window ? el.getAttribute('class') : null
      if (classStr && classStr.indexOf('el-button') >= 0) {
        btnEl = el
        break
      }
    }
    if (btnEl) btnEl.blur()
  }
}
