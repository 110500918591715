const mode = import.meta.env.MODE
const websiteHostCom = import.meta.env.VITE_WEBSITE_HOST_COM
const websiteHostCn = import.meta.env.VITE_WEBSITE_HOST_CN
export default {
  // token存放cookie key
  tokenKey: 'xToken' + (mode !== 'prod' ? `-${mode}` : ''),
  // token过期时间cookie key
  tokenExpiredKey: 'xTokenExpired' + (mode !== 'prod' ? `-${mode}` : ''),
  iframeReadyActionName: 'iframeOnReady', // iframe文档加载完毕时通知到父窗口的 action 名字
  localeLangs: {
    // 目前支持的语言列表
    'en-US': {
      websiteHome: `${websiteHostCom}/`,
      name: 'English',
    },
    'de-DE': {
      websiteHome: `${websiteHostCom}/de/`,
      name: 'German - Deutsch',
    },
    'es-ES': {
      websiteHome: `${websiteHostCom}/es/`,
      name: 'Spanish - Español',
    },
    'fr-FR': {
      websiteHome: `${websiteHostCom}/fr/`,
      name: 'French - Français',
    },
    'it-IT': {
      websiteHome: `${websiteHostCom}/it/`,
      name: 'Italian - Italiano',
    },
    'ja-JP': {
      websiteHome: `${websiteHostCom}/jp/`,
      name: 'Japanese - 日本語',
    },
    'pt-BR': {
      websiteHome: `${websiteHostCom}/br/`,
      name: 'Portuguese - Português',
    },
    'ko-KR': {
      websiteHome: `${websiteHostCom}/kr/`,
      name: 'Korean - 한국어',
    },
    'ar-SA': {
      websiteHome: `${websiteHostCom}/ar/`,
      name: 'العربية - 阿拉伯语',
    },
    'zh-CN': {
      websiteHome: `${websiteHostCn}/`,
      name: 'Chinese - 简体中文',
    },
    'zh-HK': {
      websiteHome: `${websiteHostCom}/hk/`,
      name: 'Chinese - 繁体中文',
    },
  },
}
