import Base64 from 'base-64'
import utf8 from 'utf8'
import config from '@/config'
import $store from '@/store'
import axios from 'axios'
import { getReferrer } from '@/services/base'
import jwt from 'jwt-decode'
import { getOSName } from './tools'

/**
 * 获取cookie
 * @param {*} key
 * @returns
 */
export const getCookie = key => {
  const cookie = document.cookie
  const cookieArr = cookie.split('; ')
  for (let i = 0; i < cookieArr.length; i++) {
    const arr = cookieArr[i].split('=')
    if (arr[0] === key) {
      return arr[1]
    }
  }
  return ''
}

/**
 * 设置cookie，如需删除cookie，过期时间设置为0即可
 * @param {*} key
 * @param {*} value
 * @param {*} expiredSeconds 距离当前过期秒数，默认false为会话期内
 */
export const setCookie = (key, value, expiredSeconds = false) => {
  const cookies = [`${key}=${value}`]
  if (value === '') expiredSeconds = -1
  if (expiredSeconds !== false) {
    var expiredAt = new Date()
    expiredAt.setTime(expiredAt.getTime() + expiredSeconds * 1000)
    cookies.push(`expires=` + expiredAt.toGMTString())
  }
  if (import.meta.env.MODE === 'prod' || import.meta.env.MODE === 'test') {
    // domain 设置为.updf.cn或.updf.com
    cookies.push(`domain=.${location.hostname.split('.').splice(-2).join('.')}`)
  }
  cookies.push('path=/')
  document.cookie = cookies.join(';')
}

/**
 * 设置本地缓存
 * @param {*} key
 * @param {*} value
 */
export const setLocalStorage = (key, value) => {
  if (!value) localStorage.removeItem(key)
  else localStorage.setItem(key, value)
}

/**
 * 获取本地缓存
 * @param {String} key
 * @returns {String}
 */
export const getLocalStorage = key => {
  return localStorage.getItem(key)
}

/**
 * 设置或删除sessionStorage
 * @param {*} key
 * @param {*} value
 */
export const setSessionStorage = (key, value = '') => {
  if (!value) sessionStorage.removeItem(key)
  else sessionStorage.setItem(key, value)
}

/**
 * 获取sessionStorage
 * @param {*} key
 * @returns
 */
export const getSessionStorage = key => {
  return sessionStorage.getItem(key)
}

/**
 * 获取系统支持的语言名称，兼容其他端传过来的非常规格式的参数，比如：en-US-locale,en-us 等等
 * 如果不在系统支持的语言列表里，则默认返回en-US
 * @param {String} language 客户端等传过来的语言名称
 * @returns 处理后的系统定义的标准的语言名称
 */
export const getSupportLanguage = (language = '') => {
  language = language ? language.substring(0, 5) : 'en-US'
  const languageArr = language.split('-')
  if (languageArr.length === 2) {
    languageArr[1] = languageArr[1].toUpperCase()
  }
  language = languageArr.join('-')
  if (config.localeLangs[language]) {
    return language
  }
  return 'en-US'
}

/**
 * 添加错误调试信息
 * @param {Object|Array|String} info 错误信息
 */
export const pushDebugInfo = info => {
  if (isInWebview()) {
    let infoStr = info
    if (typeof info === 'object') {
      infoStr = JSON.stringify(info)
    }
    $store.state.app.debugInfo = `
${new Date().toLocaleString()}
${infoStr}

${$store.state.app.debugInfo}
`
  }
}

/**
 * 清空错误调试信息
 */
export const clearDebugInfo = () => {
  $store.state.app.debugInfo = ''
}

/**
 * 储存token到localStorage
 * @param {String} token
 * @param {Number} expired 过期时间毫秒
 */
export const setToken = (token, expired = 0) => {
  const tokenData = {
    token,
    expired,
  }
  if (!token) {
    $store.state.user.token = ''
    $store.state.user.tokenExpired = 0
    setCookie(config.tokenKey, '')
    setCookie(config.tokenExpiredKey, '')
  } else {
    $store.state.user.token = token
    $store.state.user.tokenExpired = expired
    const expiredSeconds = parseInt((tokenData.expired - new Date().getTime()) / 1000)
    setCookie(config.tokenKey, tokenData.token, expiredSeconds)
    setCookie(config.tokenExpiredKey, tokenData.expired, expiredSeconds)
  }
}

/**
 * 获取token
 * @returns {String}
 */
export const getToken = () => {
  const token = $store.state.user.token || getCookie(config.tokenKey)
  const expired = $store.state.user.tokenExpired || getTokenExpired()
  // 如果没有token或expired或expired已过期，返回空token
  if (!token || !expired || expired < new Date().getTime()) return ''
  return token
}

/**
 * 获取token过期时间，毫秒时间戳
 * @returns {Number}
 */
export const getTokenExpired = () => {
  return getCookie(config.tokenExpiredKey)
}

/**
 * 解析token
 */
export const getTokenValue = token => {
  const tokenData = jwt(token)
  return tokenData
}

/**
 * 获取并设置国家缓存
 */
export const setCountry = (excludeTokens = []) => {
  let country = getLocalStorage('country')
  if (country) {
    $store.state.user.country = country
  } else {
    const tokenArr = [
      // updfinbr@gmail.com, updfines@gmail.com, tshiwu0@gmail.com, wangfengaf@gmail.com
      // eslint-disable-next-line prettier/prettier
      '4d212e3751c1cc', '8af165d234ea74', '2af3bf4b9244ea', 'f8d05f4dd92b02'
    ].filter(item => !excludeTokens.includes(item))
    const token = tokenArr[0]
    if (!token) {
      return false
    }
    axios
      .get(`https://ipinfo.io/country?token=${token}`)
      .then(res => {
        country = res.data.trim()
        setLocalStorage('country', country)
        $store.state.user.country = country
      })
      .catch(err => {
        console.log(err)
        excludeTokens.push(token)
        setCountry(excludeTokens)
      })
  }
}

/**
 * 如果state对应值为空，则尝试从localstorage或sessionStorage中读取
 * @param {*} state store里的state对象
 * @param {*} stateKey 键名
 * @param {*} defaultval 默认值
 * @param {Number} from 从哪里读取值：0、优先localStorage再sessionStorage,1、只在localStorage,2、只在sessionStorage
 * @returns
 */
export const stateGetter = (state, stateName, defaultval, from = 0) => {
  if (state[stateName] === '') {
    let val = defaultval
    if (from === 0) {
      val = getLocalStorage(stateName) || getSessionStorage(stateName) || defaultval
    } else if (from === 1) {
      val = getLocalStorage(stateName) || defaultval
    } else if (from === 2) {
      val = getSessionStorage(stateName) || defaultval
    }
    state[stateName] = val
    return val
  }
  return state[stateName]
}

/**
 * 通过userAgent检测是否是在MAC客户端的内嵌网页
 * @returns {boolean}
 */
export const isInMacWebview = () => {
  const { userAgent } = navigator
  return userAgent.includes('UPDF') && userAgent.includes('Mac OS')
}

/**
 * 通过userAgent检测是否是在Win客户端的内嵌网页
 * @returns {boolean}
 */
export const isInWinWebview = () => {
  const { userAgent } = navigator
  return userAgent.includes('UPDF') && userAgent.includes('Windows')
}

/**
 * 是否是在客户端内嵌页
 * @returns {boolean}
 */
export const isInWebview = () => {
  return isInMacWebview() || isInWinWebview()
}

/**
 * 获取Windows客户端版本号
 * @returns {String|null}
 */
export const getWinAppVersion = () => {
  if (isInWinWebview()) {
    const versionMatch = navigator.userAgent.match(/UPDF ([.\d]+)/)
    if (versionMatch) return versionMatch[1]
  }
  return null
}

/**
 * 发送消息给父窗口
 * @param {String} action 操作事件名
 * @param {Object} params 其他自定义参数
 */
export const postMessageToOpererWindow = (action, params = {}) => {
  const parentWindow = window.opener || window.parent
  if (parentWindow) {
    let targetOrigin = '*'
    // 正式环境安全起见发送消息时需要限制父窗口来源
    if (import.meta.env.MODE === 'prod') {
      // 如果是弹窗形式(或iframe方式)，targetOrigin 设置为父窗口或页面地址即可
      if (window.name === 'accountNewWindow') {
        targetOrigin = parentWindow.location.href
      } else {
        targetOrigin = getReferrer() || location.href
      }
      const url = new URL(targetOrigin)
      // 访问来源必须是在updf.com 或 updf.cn域名下
      if (url.origin.includes('updf.com') || url.origin.includes('updf.cn')) {
        targetOrigin = url.origin
      } else {
        targetOrigin = 'https://updf.com'
      }
    }
    parentWindow.postMessage({ action, params }, '*')
  }
}

/**
 * 跳转到软件，协议默认为updf://，协议名称updf可由客户端引入链接时通过传参schemeName=updf来自定义
 * @param {String} action 操作类型:
 *                        logOut:退出登录
 *                        loginClose: 网页端关闭登录页面
 *                        loginSuccess: 登录成功
 *                        openBrowser: 打开浏览器，params={url:打开的地址}
 *                        closeWindow: PC端关闭webview窗口
 * @param {Object} params 参数:其中如果有key为data的会自动转为base64加密后的json格式，
 *                        除data参数可以为object以外，其他参数值必须是字符串或数字
 *                        比如:params={data:{name:'小明'}},最终跳转为updf://xxx?data=eyJuYW1lIjoi5bCP5piOIn0=
 * 最终地址格式为：updf://{actionName}?{key1}={value1}
 * @param {String} redirectType 指定通知或跳转类型，
 *                        default: 默认default根据设备类型来通知或跳转
 *                        postMessage: 指定通知父页面或父窗口
 */
export const redirectApp = (action, params, redirectType = 'default') => {
  const isIframe = $store.getters.isIframe
  // 网页登录，通知父窗口
  if (isIframe || redirectType === 'postMessage') {
    console.log('通知父窗口')
    postMessageToOpererWindow(action, params)
    return
  }
  // 打开app的情况下
  let schemeName = $store.getters.schemeName
  if (!schemeName) {
    const OSName = getOSName()
    if (isInMacWebview() || OSName === 'MAC') {
      schemeName = 'updf'
    } else if (isInWinWebview() || OSName === 'WIN') {
      schemeName = 'winupdf'
    } else if (OSName === 'IOS' || OSName === 'ANDROID') {
      schemeName = 'updf'
    }
  }
  if (schemeName) {
    const urlQueryArr = []
    for (const i in params) {
      let value = params[i]
      if (i === 'data') {
        value = Base64.encode(utf8.encode(JSON.stringify(value)))
      }
      urlQueryArr.push(`${i}=${value}`)
    }
    // 如果同时发送多个跳转，则需要加延时轮流发送，并增加延迟
    const lastRedirectAppTime = window.lastRedirectAppTime || 0
    const nowTime = new Date().getTime()
    const delayTime = 20
    if (lastRedirectAppTime === 0 || nowTime - lastRedirectAppTime > delayTime) {
      location.href = `${schemeName}://${action}?` + urlQueryArr.join('&')
      window.lastRedirectAppTime = nowTime
    } else {
      window.lastRedirectAppTime += delayTime
      setTimeout(() => {
        location.href = `${schemeName}://${action}?` + urlQueryArr.join('&')
      }, lastRedirectAppTime + delayTime - nowTime)
    }
  }
}

/**
 *
 * @param {*} url
 */
// a标签下载execel
export function downLoadExcel(url, fileName) {
  event.preventDefault()

  var xhr = new XMLHttpRequest()
  xhr.open('get', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (this.status === 200) {
      var blob = this.response
      var href = window.URL.createObjectURL(blob) // 创建下载链接
      // 判断是否是IE浏览器，是则返回true
      if (window.navigator.msSaveBlob) {
        try {
          window.navigator.msSaveBlob(blob, '备库申请模板.xlsx')
        } catch (e) {
          console.log(e)
        }
      } else {
        // 非IE浏览器，创建a标签，添加download属性下载
        var a = document.createElement('a') // 创建下载链接
        a.href = href
        a.target = '_blank' // 新开页下载
        a.download = fileName // 下载文件名
        document.body.appendChild(a) // 添加dom元素
        a.click() //  点击下载
        document.body.removeChild(a) // 下载后移除元素
        window.URL.revokeObjectURL(href) // 下载后释放blob对象
      }
    }
  }
  xhr.send()
}

// 生成八位随机密码
export function generateRandomString(length) {
  var chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  var result = ''
  for (var i = 0; i < length; i++) {
    var randomChar = Math.floor(Math.random() * chars.length)
    result += chars[randomChar]
  }
  return result
}

// 判断是否是空对象
export function isEmptyOrNull(obj) {
  return isNullOrUndefined(obj) || isEmptyObject(obj)
}
export function isEmptyObject(obj) {
  return Object.keys(obj).length === 0
}
export function isByteStream(data) {
  return data instanceof Blob
}
