import { createI18n } from 'vue-i18n'
import $store from '@/store'
import config from '@/config'

const messages = {}
Object.keys(config.localeLangs).forEach(async lang => {
  const result = await import(`./lang/${lang}.js`)
  messages[lang] = result.default
})

// 默认为英文
const lang = config.localeLangs[$store.getters.language] ? $store.getters.language : 'en-US'
const i18n = createI18n({
  legacy: false, // 让 setup 函数可以通过 t 访问
  globalInjection: true, // 让 template 可以像 vue2 那样使用 $t 来访问
  locale: lang,
  messages,
})

export default i18n
