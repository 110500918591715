export default {
  state: {
    companyFormData: {
      // 创建企业的表单
      companyName: '',
      accountType: 'email', // email or phone
      codeId: '',
      code: '',
      employeesMin: 0,
      employeesMax: 0,
      employeeEmail: '',
    },
    hasPermission: true,
    userLogo: '',
  },
  getters: {},
  mutations: {
    setPermission(state, status) {
      state.hasPermission = status
    },
    setUserLogo(state, logo) {
      state.userLogo = logo
    },
  },
}
