import regexPatterns from '@/libs/regex-patterns'
import dayjs from 'dayjs'
/**
 * 正则验证字符
 * @param {String} type regex-patterns.js中定义的正则类型名称，如：phone，email
 * @param {String} value 要验证的字符串
 * @returns {Boolean}
 */
export const testValue = (type, value) => {
  const pattern = regexPatterns[type]
  if (pattern) return pattern.test(value)
  return false
}

/**
 * 获取当前地址参数值
 * @param {*} name
 * @returns
 */
export const getUrlQuery = name => {
  try {
    const reg = new RegExp(`[&|?]${name}=(.*?)&`, 'g')
    const queryMatchs = [...reg.exec(location.search + '&')]
    if (queryMatchs.length > 0) {
      return queryMatchs[1]
    }
    return ''
  } catch (e) {
    return ''
  }
}

/**
 * 生成随机数
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const randomRange = (min, max) => {
  // min最小值，max最大值
  return Math.floor(Math.random() * (max - min)) + min
}

/**
 * 本地化格式化时间
 * @param {*} time 毫秒时间戳
 * @param {*} format L:年月日，LT:时分秒，LLL:年月日时分秒，其他如：YYYY-MM-DD HH:mm:ss
 * @returns
 */
export const formatTime = (time, format) => {
  const languages = navigator.languages
  if (format === 'L') {
    return new Date(time).toLocaleDateString(languages)
  } else if (format === 'LT') {
    return new Date(time).toLocaleTimeString(languages)
  } else if (format === 'LLL') {
    return new Date(time).toLocaleString(languages)
  } else {
    return dayjs(time).format(format)
  }
}

/**
 * 获取cookie
 * @param {*} key
 * @returns
 */
export const getCookie = key => {
  const cookie = document.cookie
  const cookieArr = cookie.split('; ')
  for (let i = 0; i < cookieArr.length; i++) {
    const arr = cookieArr[i].split('=')
    if (arr[0] === key) {
      return arr[1]
    }
  }
  return ''
}

/**
 * 设置cookie，如需删除cookie，过期时间设置为0即可
 * @param {*} key
 * @param {*} value
 * @param {*} expiredSeconds 过期秒数，默认false为会话期内
 */
export const setCookie = (key, value, expiredSeconds = false) => {
  var expiredAt = new Date()
  expiredAt.setTime(expiredAt.getTime() + expiredSeconds * 1000)
  if (expiredSeconds === false) {
    document.cookie = key + '=' + value + ';path=/'
  } else {
    document.cookie = key + '=' + value + ';expires=' + expiredAt.toGMTString() + ';path=/'
  }
}

/**
 * 生成指定区间的随机整数，包含min，max
 * @param {*} min
 * @param {*} max
 * @returns
 */
export const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * 复制文字
 * @param {*} text
 */
export const copyText = text => {
  const textarea = document.createElement('textarea')
  textarea.value = text
  textarea.style.position = 'fixed'
  textarea.style.right = 0
  textarea.style.bottom = 0
  textarea.style.opacity = 0
  document.body.appendChild(textarea)
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

/**
 * 获取浏览器客户端类型
 * @returns {String} WIN、MAC、ANDROID、IOS、OTHER
 */
export const getOSName = () => {
  const userAgent = navigator.userAgent
  if (/^Win/.test(navigator.platform)) {
    return 'WIN'
  } else if (/^Mac/.test(navigator.platform)) {
    return 'MAC'
  } else if (userAgent.indexOf('Android') > -1 || userAgent.indexOf('Adr') > -1) {
    return 'ANDROID'
  } else if (userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)) {
    return 'IOS'
  } else if (userAgent.indexOf('Linux') >= 0) {
    return 'LINUX'
  } else {
    return 'OTHER'
  }
}

/**
 * 相对屏幕居中打开一个新窗口
 * @param {String} url
 * @param {String} name 窗口name
 */
export const openNewWindow = (url, name = 'accountNewWindow') => {
  const width = 900
  const height = 700
  const windowW = window.screen.width
  const windowH = window.screen.height
  const left = (windowW - width) / 2 - 10
  const top = (windowH - height) / 2
  const newWindow = window.open(url, name, `height=${height}, width=${width}, top=${top}, left=${left}, toolbar=no, menubar=no, scrollbars=no,resizable=no, location=no, status=no`)
  window.newWindow = newWindow
  return newWindow
}

/**
 * 关闭打开的新窗口(仅网页端弹窗，例如：谷歌苹果登录弹窗)
 */
export const closeNewWindow = () => {
  if (window.newWindow) {
    window.newWindow.close()
    window.newWindow = null
  }
}

/**
 * 比较两个版本号大小：比如:compareVersions('1.3.0.1', '1.3.0.2')
 * @param {String} v1
 * @param {String} v2
 * @returns {Number} 返回：-1: v1 < v2
 *                         0: v1 = v2
 *                         1: v1 > v2
 */
export const compareVersions = (v1, v2) => {
  var v1parts = v1.split('.')
  var v2parts = v2.split('.')
  var n1 = v1parts.length
  var n2 = v2parts.length
  for (var i = 0; i < Math.min(n1, n2); i++) {
    var diff = v1parts[i] - v2parts[i]
    if (diff !== 0) return diff > 0 ? 1 : -1
  }
  return n1 - n2 <= 0 ? (n1 === n2 ? 0 : -1) : 1
}

/**
 * 判断银行卡是否过期
 * @param {*} expireYear
 * @param {*} expireMonth
 * @returns
 */
export function isCardExpired(expireYear, expireMonth) {
  const currentYear = new Date().getFullYear()
  const currentMonth = new Date().getMonth() + 1 // Adding 1 to get the current month in the same format as the input
  const cardYear = parseInt(expireYear)
  const cardMonth = parseInt(expireMonth)

  if (cardYear < currentYear) {
    return true
  } else if (cardYear === currentYear && cardMonth < currentMonth) {
    return true
  } else {
    return false
  }
}

/**
 *
 * @param {产品ID数组} productIdArray
 * @returns
 */
export function getProductTypeByIdArray(productIdArray) {
  const productTypes = {
    25643: 'UPDF',
    25644: 'UPDF',
    25645: 'UPDF',
    25646: 'UPDF',
    25647: 'UPDF',
    25652: 'UPDF AI Unlimited',
    25648: 'UPDF AI Standard',
    25653: 'UPDF AI Unlimited',
    25651: 'UPDF AI Standard',
  }

  const isUPDF = productIdArray.every(productId => productTypes[productId] === 'UPDF')

  if (isUPDF) {
    return 'UPDF'
  } else {
    for (const productId of productIdArray) {
      if (productTypes[productId]) {
        return productTypes[productId]
      }
    }
    return 'UPDF' // Return "Unknown" if none of the IDs match
  }
}
/**
 *
 * @param {时间} date
 * @returns
 */
// 格式化订单列表时间
export function formatDate(date) {
  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0') // 月份从 0 开始，需要加 1
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}
