import Vuex from 'vuex'
import app from './modules/app'
import user from './modules/user'
import company from './modules/company'
export default new Vuex.Store({
  modules: {
    user,
    app,
    company,
  },
  getters: {},
  state: {},
  mutations: {},
  actions: {},
})
