<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { buttonAutoBlurWhenClicked } from './libs/elementUiInit'
import { prototypeFunc } from './libs/prototype'
import { clearDebugInfo, isInMacWebview, isInWebview, setCountry } from './libs/utils'
import { redirectToLogin, webviewHidden, webviewVisibility } from './services/base'

const $store = useStore()

const lang = $store.getters.language
setCountry()
prototypeFunc()
buttonAutoBlurWhenClicked()
// 关闭Mac webview时
window.onClosedWebview = () => {
  redirectToLogin()
}
// webview 隐藏时
window.onHiddenWebview = () => {
  webviewHidden()
}
// webview 显示时
window.onVisibilityWebview = () => {
  webviewVisibility()
}
// 非mac webview时默认置为显示状态
if (!isInMacWebview()) {
  window.onVisibilityWebview()
}
// 给Html增加语言class
document.querySelector('html').classList.add(lang)

// 错误调试信息
const debugInfo = computed(() => {
  const info = $store.state.app.debugInfo
  if (typeof info === 'object') {
    return JSON.stringify(info)
  }
  return info
})
// 调试信息，只在内嵌页显示，测试环境直接显示，正式环境点击左侧背景图5次后再显示
const debugInfoShow = ref(false)
const testClickCount = ref(0)
setTimeout(() => {
  const loginBgEl = document.querySelector('.login-bg')
  if (loginBgEl) {
    document.querySelector('.login-bg').addEventListener('click', () => {
      testClickCount.value++
    })
  }

  console.log()
}, 5000)
// safria字体类名
function isSafari() {
  var userAgent = navigator.userAgent
  return /Safari/.test(userAgent) && !/Chrome/.test(userAgent)
}
if (isSafari()) {
  document.querySelector('html').classList.add('safari')
}
</script>

<template>
  <router-view></router-view>
  <div class="debug-container" v-if="isInWebview()" v-show="$store.getters.mode !== 'prod' || testClickCount >= 10">
    <span class="clear" v-if="debugInfoShow" @click="clearDebugInfo()">清空</span>
    <span class="close" v-if="debugInfoShow" @click="debugInfoShow = false">关闭调试</span>
    <span class="open" v-if="!debugInfoShow" @click="debugInfoShow = true">打开调试</span>
    <textarea class="debug-info" v-model="debugInfo" readonly v-if="debugInfoShow"></textarea>
  </div>
</template>

<style>
@import url(@/styles/animation.scss);
@font-face {
  font-family: 'Product Sans';
  src: url(@/assets/fonts/GoogleSans-Regular.ttf);
}
@font-face {
  font-family: 'Product Sans';
  src: url(@/assets/fonts/GoogleSans-Bold.ttf);
  font-weight: bold;
}
/* @font-face {
  font-family: 'Product Sans';
  src: url(@/assets/fonts/Product-Sans-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'Product Sans';
  src: url(@/assets/fonts/Product-Sans-Bold.woff2) format('truetype');
  font-weight: bold;
} */
@font-face {
  font-family: 'SF pro';
  src: url(@/styles/fonts/SF-Pro-Text-Regular.ttf);
}
* {
  margin: 0;
  padding: 0;
}
html {
  height: 100%;
}

.ar-SA {
  direction: rtl;
}
body,
#app {
  font-family: 'Product Sans', var(--el-font-family);
  overflow: auto;
  min-height: 100%;
  display: unset;
  position: inherit !important;
  /* letter-spacing: 0.02em; */
}

html.safari {
  body,
  #app {
    font-family: 'PingFang SC', 'Noto Sans SC';
  }
}
</style>
<style lang="scss" scoped>
.debug-container {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  .debug-info {
    width: 100%;
    height: 200px;
  }
  span {
    font-size: 12px;
    margin-right: 15px;
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.ar-SA {
  direction: rtl;
  #app {
    .user-center-container {
      .section {
        .form-section {
          .plan-list {
            .plan-item {
              margin: 0 10px 10px;
              .plan {
                .plan-type {
                  align-items: flex-start;
                }
              }
            }
          }
          .form-item {
            .el-overlay {
              .account-manager-dialog {
                left: 50%;
                position: absolute;
                .el-dialog__header {
                  display: flex;
                  flex-direction: row-reverse;
                  align-items: center;
                }
              }
            }
          }
        }
      }
    }
    .gsi-material-button .gsi-material-button-icon {
      margin-left: 10px;
    }
  }
}
</style>
