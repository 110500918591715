// replaceAll
const stringReplaceAll = () => {
  if (!String.prototype.replaceAll) {
    String.prototype.replaceAll = function (targetStr, newStr) {
      var sourceStr = this.valueOf()
      while (sourceStr.indexOf(targetStr) !== -1) {
        sourceStr = sourceStr.replace(targetStr, newStr)
      }
      return sourceStr
    }
  }
}

// 重写一些低版本不支持的方法
export const prototypeFunc = () => {
  stringReplaceAll()
}
